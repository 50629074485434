var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rank"},[_c('div',{staticClass:"top"},[_c('Nav',{attrs:{"menu":_vm.menu}}),_vm._v(" "),_c('v-date-picker',{attrs:{"attributes":[{
        key: 'today',
        highlight: 'yellow',
        dates: _vm.date
      }],"min-date":_vm.minDate,"max-date":_vm.maxDate,"mode":"single","popover":{
        placement: 'bottom',
        visibility: 'click' 
      }},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('div',{staticClass:"calendar"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.dateNum))])])])],1),_vm._v(" "),(_vm.artList.length>=3)?_c('Top3',{attrs:{"artList":_vm.artList.slice(0,3)}}):_vm._e(),_vm._v(" "),(_vm.artList.length>3)?_c('van-list',{staticClass:"rank-list",attrs:{"finished":_vm.finished,"finished-text":"没有更多了","error":_vm.error,"error-text":"网络异常，点击重新加载"},on:{"update:error":function($event){_vm.error=$event},"load":_vm.getRankList},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"card-box"},[_c('div',{staticClass:"column"},_vm._l((_vm.odd(_vm.artList.slice(3))),function(art){return _c('ImageCard',{key:art.id,attrs:{"mode":"cover","artwork":art},on:{"click-card":function($event){return _vm.toArtwork($event)}}})}),1),_vm._v(" "),_c('div',{staticClass:"column"},_vm._l((_vm.even(_vm.artList.slice(3))),function(art){return _c('ImageCard',{key:art.id,attrs:{"mode":"cover","artwork":art},on:{"click-card":function($event){return _vm.toArtwork($event)}}})}),1)])]):_vm._e(),_vm._v(" "),_c('van-loading',{directives:[{name:"show",rawName:"v-show",value:(!_vm.artList || _vm.artList.length===0),expression:"!artList || artList.length===0"}],staticClass:"loading",attrs:{"size":'50px'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }