import { render, staticRenderFns } from "./SafeAreaLayout.vue?vue&type=template&id=78484283&"
import script from "./SafeAreaLayout.vue?vue&type=script&lang=js&"
export * from "./SafeAreaLayout.vue?vue&type=script&lang=js&"
import style0 from "./SafeAreaLayout.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports